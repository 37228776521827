<template>
  <div>
    <a-table
      :columns="shareholder.columns"
      :dataSource="shareholder.dataSource"
      :loading="ui.loading"
      rowKey="id"
    ></a-table>
  </div>
</template>

<script>
import { getShareHolderList } from "@/api/company";

const shareholderColumns = [
  {
    title: "名称",
    dataIndex: "name",
  },
  {
    title: "持股比例",
    dataIndex: "percent",
  },
  {
    title: "认缴出资额（万元）",
    dataIndex: "capital",
  },
];

export default {
  data() {
    return {
      shareholder: {
        columns: shareholderColumns,
        dataSource: [],
        info: {},
      },
      ui: {},
    };
  },
  created() {
    this.companyId = this.$route.params.companyId;
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.ui.loading = true;
      getShareHolderList({
        companyId: this.companyId,
      }).then((res) => {
        this.shareholder.dataSource = res.data;
        this.ui.loading = false;
      });
    },
  },
};
</script>